@media print {
  [data-testid="Authorized"] {
    display: none !important;
  }

  [data-testid="nav-links"] {
    display: none !important;
  }

  [data-testid="burger-menu"] {
    display: none !important;
  }

  [data-testid="sticky-nav"] {
    display: none !important;
  }
}
