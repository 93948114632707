@media print {
  [data-testid="statement-header"] {
    display: none !important;
  }

  [data-testid="statement-cap"] {
    display: none !important;
  }

  [data-testid="statement"] {
    padding: 0 !important;
  }
  [data-testid="statement-body"] {
    padding: 0 !important;
  }


  [data-testid="account-summary-header"] {
    flex-direction: row !important;
    align-items: baseline;
  }

  [data-testid="account-summary-body"] {
    flex-direction: row !important;
    gap: 16px;
  }

  [data-testid="account-summary-body"] > div {
    padding-left: 0;
    padding-right: 0;
  }



  [data-testid="your-detail-body"] {
    flex-direction: row !important;
    gap: 16px;
  }

  [data-testid="your-detail-body"] > div {
    flex:1;
    margin-top: 0;
  }


  [data-testid="transaction-box"] {
    min-width: unset !important;
  }


  [data-testid="footer"] {
    flex-direction: row !important;
  }



  .r-fontSize-ubezar {
    font-size: x-small !important;
  }
  .r-fontSize-1b43r93 {
    font-size: small !important;
  }
  .r-fontSize-1x35g6 {
    font-size: medium !important;
  }
  .r-fontSize-adyw6z {
    font-size: small !important;
  }
  .r-fontSize-1ra0lkn {
    font-size: x-large !important;
  }
  .r-fontSize-1enofrn {
    font-size: xx-small !important;
  }


}
